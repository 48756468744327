import React from 'react'

export const NoImageIcon = () => {
  return (
    <div className="error-loading-image-container">
      <svg width="73" height="100" viewBox="0 0 73 100" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M38.8537 35.8862V37.7932L33.7582 44.9558V35.9337L10.6289 49.8608L24.3084 58.2399L21.3548 62.3916L7.64362 53.9931V75.9687L10.542 77.5899L7.57832 81.7558L3.84894 79.6698C3.0455 79.2204 2.5481 78.3737 2.5481 77.4554V5.08709C2.5481 4.17501 3.03891 3.33299 3.83391 2.88122C4.6289 2.42945 5.60593 2.43734 6.39348 2.90188L37.6035 21.3114C38.3784 21.7684 38.8537 22.5992 38.8537 23.4966V29.985L42.7671 32.2933L39.8098 36.4502L38.8537 35.8862ZM33.7582 30.0003V24.9444L7.64362 9.54053V45.725L33.7582 30.0003ZM33.7582 90.5758L19.3209 82.5004L16.3573 86.6663L35.059 97.127C35.8479 97.5683 36.8125 97.5595 37.5932 97.104C38.3739 96.6484 38.8537 95.8143 38.8537 94.9127V70.0044L44.8198 66.4245L66.1739 79.6138C66.9597 80.0991 67.9476 80.1222 68.7553 79.674C69.563 79.2258 70.0637 78.3768 70.0637 77.4554V49.8581C70.0638 49.8496 70.0638 49.8412 70.0638 49.8327C70.0636 49.7988 70.0628 49.7649 70.0614 49.7312C70.0235 48.8573 69.5358 48.0609 68.7667 47.629C68.7625 47.6266 68.7582 47.6242 68.754 47.6219L51.4118 37.3925L48.4545 41.5494L62.5454 49.8609L38.8537 64.0766V55.044L33.7582 62.2065V64.0281L32.856 63.4755L29.9024 67.6273L33.7582 69.989V90.5758ZM49.7815 63.5145L64.9682 72.8945V54.3477L49.7815 63.5145Z" fill="black"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M71.5405 0.471944C72.6871 1.28761 72.9553 2.87831 72.1396 4.02486L4.62398 98.9289C3.80831 100.075 2.21762 100.344 1.07106 99.5281C-0.07549 98.7124 -0.343725 97.1217 0.471944 95.9751L67.9876 1.07106C68.8033 -0.07549 70.394 -0.343725 71.5405 0.471944Z" fill="black"/>
      </svg>
    </div>
  )
}