import { Button, Card } from "@mui/material";
import 'i18n';
import { t } from 'i18next';
import { useRouter } from "next/router";


export const ErrorPage = (props: {error: string, buttonLabel?: string, buttonRedirectionUrl?: string}) => {

    const router = useRouter();

    return (
        <div className="limited-width">
            <Card className="error-page-box main-borders-margin" elevation={2}>

                <img src={"/assets/market_item_placeholder.png"} alt={"placeholder"} width={160} height={160}/>

                <p className="error-page-label">
                    {props.error}
                </p>

                <Button
                    className="sedeo-button-rounded"
                    onClick={() => {
                        if(props.buttonRedirectionUrl) {
                            router.push(props.buttonRedirectionUrl)
                        }else {
                            router.back()
                        }
                    }}
                >
                    {props.buttonLabel ? props.buttonLabel : t("BUTTONS.Back")}
                </Button>

            </Card>
        </div>
    )
}