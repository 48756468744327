import ProductsAPIHelper from "../products/ProductsAPIHelper";
import {PricedItem} from "../../../api/models/item";

export interface CartDiffResult {
    itemsNotAvailable: PricedItem[] // list of items that aren't available anymore
    availableItems: PricedItem[] // list of items that are available
    availableItemsWithPriceChange: { item: PricedItem, oldPricePerUnit: number, newPricePerUnit: number }[] // list of items that are available but with a price change
}

export default class CartDiffHelper {

    /**
     *
     * Takes in an array of PricedItem and check all items availability and prices given a spatialToken.
     * If an item isn't available anymore it will not be present in the CartType returned.
     *
     * @param oldItems
     * @param spatialToken
     */

    static getDifference = async (oldItems: PricedItem[], spatialToken: string): Promise<CartDiffResult> => {
        return await new Promise((resolve, reject) => {
            // TODO We should only fetch the items present in the cart. Right now the choice is to either fetch the whole catalog
            // TODO or every item separately. The call that will resemble the most what we'll do right now is to fetch the whole catalog
            ProductsAPIHelper.listCatalog([], spatialToken).then(catalogItems => {

                let itemsNotAvailable: PricedItem[] = []
                let availableItems: PricedItem[] = []
                let availableItemsWithPriceChange: { item: PricedItem, oldPricePerUnit: number, newPricePerUnit: number }[] = []

                oldItems.forEach(oldItem => {
                    let availableCatalogItem = catalogItems.find(catalogItem => catalogItem.getUuid() === oldItem.uuid)
                    if(availableCatalogItem){

                        if(availableCatalogItem.getPricesList().length > 0) {
                            let oldPricePerUnit = oldItem.exc_tax_unit_price
                            let newPricePerUnit = parseFloat((availableCatalogItem.getPricesList()[0].getPrice() / availableCatalogItem.getPricesList()[0].getPackSize()).toFixed(2))*100
                            let availableItem = Object.assign({}, oldItem);
                            availableItem.exc_tax_total_price = oldItem.exc_tax_total_price
                            availableItem.exc_tax_unit_price = newPricePerUnit
                            availableItems.push(availableItem)

                            if(oldPricePerUnit !== newPricePerUnit){
                                availableItemsWithPriceChange.push({item: oldItem, oldPricePerUnit: oldPricePerUnit, newPricePerUnit: newPricePerUnit})
                            }
                        }else{
                            // If no prices are present, it means the item is not available anymore
                            itemsNotAvailable.push(oldItem)
                        }
                    }else{
                        itemsNotAvailable.push(oldItem)
                    }
                })

                resolve({
                    itemsNotAvailable,
                    availableItems,
                    availableItemsWithPriceChange
                })
            }).catch(err => {
                reject(`check. rejected ${err}`)
            })
        })
    }


}