import { Delete } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Link from "next/link";
import { memo } from "react";
import { OrderCartItemViewModel } from "../../features/order/cart/view-model";
import { LazyImage } from "../common/LazyImage";
import { NoImageIcon } from "../common/NoImageIcon";
import { QuantitySelector } from "../common/QuantitySelector";

export const CartItemLayout = memo(function CartItemLayout(props: {
        cartItem: OrderCartItemViewModel,
        onClick: () => void, 
        increaseItemQuantity: (c: OrderCartItemViewModel) => void, 
        decreaseItemQuantity: (c: OrderCartItemViewModel) => void,
        setItemQuantity: (c: OrderCartItemViewModel, q: number) => void
        removeItemFromCart: (c: OrderCartItemViewModel) => void
    }) {

    const {cartItem, onClick, increaseItemQuantity, decreaseItemQuantity, setItemQuantity, removeItemFromCart} = props

    const renderImage = (cartItem: OrderCartItemViewModel) =>{
        if(cartItem.images.length > 0){
            // Show the image with lowest order
            let imageToShow = cartItem.images.reduce(function(prev, curr) {
                return prev.order < curr.order ? prev : curr;
            });
            return <LazyImage
                src={imageToShow.file_key}
                alt={cartItem.name}
                width={80}
                height={80}
                />
        }else{
            return <NoImageIcon/>
        }
    }


    return (
        <div className='cart-item-layout hoverable-bg'>

            <div className='item-info'>
                <Link className='item-image'
                    onClick={() => onClick()} 
                    href={cartItem.url}>
                    {renderImage(cartItem)}
                </Link>

                <div className='item-text'>
                    <Link onClick={() => onClick()}
                        href={cartItem.url}>
                        <h4>{cartItem.name}</h4>
                        {/* <p>link: {cartItem.productLink}</p> */}
                    </Link>
                    
                    {/* {(relatedCategoryName && relatedSubCategoryName) ? 
                        <p>dans <strong>{relatedCategoryName}</strong> &gt; <strong>{relatedSubCategoryName}</strong></p>
                        :
                        <LoadingAnimation/>
                    } */}
                    {/* <LoadingAnimation/> */}

                    <div className='quantity-container'>
                        <QuantitySelector quantity={cartItem.quantity}
                            // decreaseItemQuantity={() => dispatch(decreaseItemsFromCart(cartItem.data.uuid, 1))}
                            // setItemQuantity={(quantity: number) => dispatch(setCartItemQuantity(cartItem, quantity))}
                            // increaseItemQuantity={() => dispatch(addItemsToCart(cartItem, 1))}
                            decreaseQuantity={() => decreaseItemQuantity(cartItem)}
                            setQuantity={(quantity: number) => setItemQuantity(cartItem, quantity)}
                            increaseQuantity={() => increaseItemQuantity(cartItem)}
                            />
                    </div>

                </div>

                <div className="item-price">
                {
                    cartItem.quantity > 0 &&
                    <>
                        <p className='item-unit-price'>
                            {(cartItem.quantity * cartItem.excTaxProductUnitPrice / cartItem.quantity).toFixed(2)}€ HT x {cartItem.quantity}
                        </p>
                        <p className='item-total-price'>
                            {(cartItem.quantity * cartItem.excTaxProductUnitPrice).toFixed(2)}€ HT
                        </p>
                    </>
                }
                </div>

                <IconButton className='delete-button' onClick={() => removeItemFromCart(cartItem)}>
                    <Delete fontSize="small" />
                </IconButton>

            </div>
            
        </div>
    )
})