import SearchIcon from '@mui/icons-material/Search';
import {Badge, Button, useMediaQuery, useTheme} from '@mui/material';
import 'i18n';
import {t} from 'i18next';
import Link from "next/link";
import {useRouter} from "next/router";
import {FC, useEffect, useState} from "react";
import {useCookies} from 'react-cookie';
import {ReactSVG} from 'react-svg';
import {LOGIN_REFERRER_ORIGIN} from '../../../pages/account/login';
import {CartTooltip} from '../../features/cartTooltip/CartTooltip';
import {USER_CONNECTION_STATUS} from "../../redux/session/session.reducer";
import AmplitudeHelper, {SeeCartRecapOrigin} from "../../utils/AmplitudeHelper";
import RoutesHelper, {ROUTES} from "../../utils/consts";
import {useTypedSelector} from "../../utils/use-typed-selector";
import {EventSelectorBar} from './EventSelectorBar';

interface HeaderProps{
    isUserInHome: boolean
    isUserInMarket: boolean
    isUserInOrders: boolean
    isUserInSearch: boolean
}
export const Header: FC<HeaderProps> = (props) => {

    const {isUserInHome} = props

    const theme = useTheme();
    const router = useRouter();
    const screenDownMD = useMediaQuery(theme.breakpoints.down('md'));

    // This one is just for css class to toggle the info panel
    const [closeInfoProp, setCloseInfoProp] = useState('');
    const [currentCartItemQuantity, setCurrentCartItemQuantity] = useState(0);
    const [uniqueItemsCount, setUniqueItemsCount] = useState(0);

    const cartState = useTypedSelector((state) => state.eventReducer.currentEvent?.cart);
    const cartUniqueItemsCount = useTypedSelector((state) => state.eventReducer.currentEvent?.cart?.products.items?.length);
    const hasSelectedDates = useTypedSelector((state) => state.eventReducer.currentEvent?.cart?.transport_schedule.delivery !== undefined && state.eventReducer.currentEvent?.cart?.transport_schedule.delivery.start !== '' && state.eventReducer.currentEvent?.cart?.transport_schedule.delivery.end !== '' && state.eventReducer.currentEvent?.cart?.transport_schedule.retrieval !== undefined && state.eventReducer.currentEvent?.cart?.transport_schedule.retrieval.start !== '' && state.eventReducer.currentEvent?.cart?.transport_schedule.retrieval.end !== '')
    const cartItemsQuantities = useTypedSelector((state) => state.eventReducer.currentEvent?.cart?.products.items ? state.eventReducer.currentEvent.cart.products.items.map(item => item.quantity) : []);
    const userSession = useTypedSelector((state) => state.sessionReducer.userSession);

    const [profileIcon, setProfileIcon] = useState(<ReactSVG src='/assets/profile.svg'/>);
    const SPToken = useTypedSelector((state) => state.eventReducer.currentEvent?.SPToken ?? undefined);
    
    const [cookies, setCookie, removeCookie] = useCookies(['TopRibbon']);
    const [hasTopRibbonCloseCookie, setHasTopRibbonCloseCookie] = useState<boolean>(true) // True by default so that the cookie dialog is not rendered on each first-render. We check if we have the user agreement when the component is mounting.

    const [showTooltip, setShowTooltip] = useState(false);
    
    useEffect(() => {
        setHasTopRibbonCloseCookie(cookies.TopRibbon)
    }, [cookies])

    const hideInfo = () => {
        setCloseInfoProp('close-info-panel');
        setTimeout(() => {
            setCookie('TopRibbon', true, { path: '/' });
        }, 200);
    }

    useEffect(() => {
        // Show first letter of user in the header profile button.
        // If the firstname and lastname aren't set we show the first letter of the mail address
        switch (userSession?.status) {
            case USER_CONNECTION_STATUS.LOGGED_IN:
                const letters = userSession.user.data.firstname?.length > 0 && userSession.user.data.lastname?.length > 0 ?
                    (userSession.user.data.firstname[0] + userSession.user.data.lastname[0])
                    :
                    userSession.user.data.email[0]
                setProfileIcon(<span className='profile-letters-icon'>{letters}</span>)
                break;
            case USER_CONNECTION_STATUS.LOGGED_OFF:
                setProfileIcon(<ReactSVG src='/assets/profile.svg'/>)
                break;
            default: const _exhaustiveCheck: never = userSession;

        }
    }, [userSession])

    const displayTooltip = () => {
        setTimeout(() => {
            setShowTooltip(true)
        }, 200)
    }

    const hideTooltip = () => {
        setTimeout(() => {
            setShowTooltip(false)
        }, 200)
    }

    useEffect(() => {
        setUniqueItemsCount(cartUniqueItemsCount ?? 0)
    }, [cartUniqueItemsCount])

    useEffect(() => {
        //this is just to add a little bounce animation to the cart icon when a quantity of any item changes :D
        let currentItemsCount = cartItemsQuantities?.reduce((a,b) => a = a + b , 0) ?? 0

        if(cartItemsQuantities && cartItemsQuantities.length > 0 && currentItemsCount !== currentCartItemQuantity){
            const cart = document.getElementById('cart-icon');
            if(cart) {
                cart.classList.add('items-quantity-changed');
                setTimeout(() => {
                    cart.classList.remove('items-quantity-changed');
                }, 500);
            }
            setTimeout(() => {
                setCurrentCartItemQuantity(currentItemsCount);
            }, 600);
        }
    }, [cartItemsQuantities])

    const OutOfStockBanner = () => {
        return <div className={'out-of-stock ' + closeInfoProp}>
            <ReactSVG className='out-of-stock-sedeo-icon' src="/assets/logo-lined.svg" title="rupture"/>

            <div className="notes">
                <strong>{t("HEADER.AppBar.Title")} : </strong>
                <span>{t("HEADER.AppBar.Note1")}</span>
                <p>{t("HEADER.AppBar.Note2")}</p>
            </div>

            <div className="close clickable" onClick={hideInfo}>
                <ReactSVG className='close-icon' src="/assets/close.svg" title="close"/>
            </div>
        </div>
    }

    return (
        <header id="sedeo-appbar-container" className="sedeo-appbar-container">

            {!hasTopRibbonCloseCookie && SPToken && hasSelectedDates && <OutOfStockBanner/>}

            <div className="main-appbar">
                <div className="left">
                    {
                        screenDownMD ?
                            <Link className='logo-short' href="/">
                                <ReactSVG src="/assets/sedeo_logo_short.svg" title="sedeo-logo"/>
                            </Link>
                            :
                            <Link className='logo' href="/">
                                <img src="/assets/sedeo-logo.svg" width={201} height={84} title="sedeo-logo"  alt="sedeo-logo"/>
                            </Link>
                    }
                    <div className="eventbar-container">
                        <EventSelectorBar 
                            isUserInHome={isUserInHome}
                            screenDownMD={screenDownMD}
                            SPToken={SPToken}
                            router={router}
                            />
                    </div>
                </div>

                <div className="right">
                    <Button variant="outlined"
                            className="search-button-mobile sedeo-button-rounded clickable hoverable"
                            onClick={() => {
                                if(isUserInHome) AmplitudeHelper.trackHomeCTA("Search")
                                router.push(RoutesHelper.getMarketSearch())
                            }}
                            aria-label={"recherche"}
                    >
                        <SearchIcon/>
                    </Button>
                    <Button variant="outlined"
                            className="search-button sedeo-button-rounded clickable"
                            onClick={() => {
                                if(isUserInHome) AmplitudeHelper.trackHomeCTA("Search")
                                router.push(RoutesHelper.getMarketSearch())
                            }}
                    >
                        <span>{t('SEARCH.SearchTopBarPlaceholder')}</span>
                        <SearchIcon/>
                    </Button>
                    <div className="profile-icon clickable hoverable"
                        onClick={() => {
                            router.push({
                                pathname: userSession.status === USER_CONNECTION_STATUS.LOGGED_IN ? ROUTES.ACCOUNT.ORDERS : ROUTES.ACCOUNT.LOGIN,
                                query: {
                                    referrer: LOGIN_REFERRER_ORIGIN.ACCOUNT_ORDERS
                                }
                            })
                        }}
                    >
                        {profileIcon}
                    </div>

                    <div className='cart-icon-container' 
                        onMouseEnter={() => displayTooltip()}
                        >
                        <div id='cart-icon' className='cart-icon clickable hoverable'>
                            <Badge badgeContent={uniqueItemsCount > 0 ? uniqueItemsCount : null}>
                                <ReactSVG
                                    src='/assets/cart.svg'
                                    onClick={() => {
                                        AmplitudeHelper.trackSeeCartRecap(SeeCartRecapOrigin.SIDE_MENU, cartState?.prices.total.exc_tax_total_price ?? 0, cartState?.products.items ?? [])
                                        router.push(ROUTES.ORDERS.CART)
                                    }}
                                    />
                            </Badge>
                        </div>
                        
                        {showTooltip && <div className='handmade-tooltip'
                            onMouseEnter={() => setShowTooltip(true)}
                            onMouseLeave={hideTooltip}>
                            <CartTooltip hideTooltip={hideTooltip}/>
                        </div>}

                    </div>

                    
                </div>
            </div>

        </header>
    );
}
