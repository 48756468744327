import { Dispatch, SetStateAction, useState } from "react";
import { GetOrCreateAddressResponse } from "../../api/addresses/dto/GetOrCreateAddress";
import { MinimizedVenue } from "../../api/models/venues";
import { EventDataType } from "../../redux/event/EventDataType";
import { SpatialDataResult } from "../../typing/types";
import { LocationFormInfo } from "../../ui/eventDataForm/EventDataForm";
import { CartDiffResult } from "../../utils/api/cart/CartDiffHelper";
import { useDialog } from "../hooks/use-dialog";

export interface StateType{
    openConfirmDialog: boolean,
    handleOpenConfirmDialog: () => void,
    handleCancelConfirmDialog: () => void,

    locationFormInfo: LocationFormInfo | undefined;
    setLocationFormInfo: Dispatch<SetStateAction<LocationFormInfo | undefined>>
    isLoadingVenues: boolean,
    setIsLoadingVenues: Dispatch<SetStateAction<boolean>>
    eventDataToUpdate: EventDataType | undefined,
    setEventDataToUpdate: Dispatch<SetStateAction<EventDataType | undefined>>,
    cartDifferences: CartDiffResult | undefined,
    setCartDifferences: Dispatch<SetStateAction<CartDiffResult | undefined>>,
    spatialDataResult: SpatialDataResult | undefined,
    setSpatialDataResult: Dispatch<SetStateAction<SpatialDataResult | undefined>>,
    isPreparingCart: boolean,
    setIsPreparingCart: Dispatch<SetStateAction<boolean>>,
    knownVenues: MinimizedVenue[],
    setKnownVenues: Dispatch<SetStateAction<MinimizedVenue[]>>,
    selectedVenue: MinimizedVenue|null,
    setSelectedVenue: Dispatch<SetStateAction<MinimizedVenue|null>>,
    selectedAddress: GetOrCreateAddressResponse | undefined,
    setSelectedAddress: Dispatch<SetStateAction<GetOrCreateAddressResponse | undefined>>,
    locationError: string|null,
    setLocationError: Dispatch<SetStateAction<string|null>>,
}

export const usePageState = (): StateType => {
    const [isPreparingCart, setIsPreparingCart] = useState(false)

    const [openConfirmDialog, handleOpenConfirmDialog, handleCancelConfirmDialog] = useDialog()

    const [locationFormInfo, setLocationFormInfo] = useState<LocationFormInfo|undefined>(undefined)
    const [eventDataToUpdate, setEventDataToUpdate] = useState<EventDataType>()
    const [cartDifferences, setCartDifferences] = useState<CartDiffResult>()
    const [spatialDataResult, setSpatialDataResult] = useState<SpatialDataResult>()
    const [knownVenues, setKnownVenues] = useState<MinimizedVenue[]>([])
    const [selectedVenue, setSelectedVenue] = useState<MinimizedVenue|null>(null)
    const [selectedAddress, setSelectedAddress] = useState<GetOrCreateAddressResponse|undefined>(undefined)
    const [isLoadingVenues, setIsLoadingVenues] = useState(false)
    const [locationError, setLocationError] = useState<string|null>(null)

    return {
        openConfirmDialog, handleOpenConfirmDialog, handleCancelConfirmDialog,
        locationFormInfo, setLocationFormInfo,
        eventDataToUpdate, setEventDataToUpdate,
        cartDifferences, setCartDifferences,
        spatialDataResult, setSpatialDataResult,
        isPreparingCart, setIsPreparingCart,
        knownVenues, setKnownVenues,
        selectedVenue, setSelectedVenue,
        selectedAddress, setSelectedAddress,
        isLoadingVenues, setIsLoadingVenues,
        locationError, setLocationError
    }
}