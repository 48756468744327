import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import 'i18n';
import { t } from 'i18next';
import { FC } from "react";
import { useTypedSelector } from '../../../../utils/use-typed-selector';


interface EmptyCartConfirmationDialogProps {
    open: boolean;
    onCancel: () => void;
    onConfirm: () => void;
}
export const EmptyCartConfirmationDialog: FC<EmptyCartConfirmationDialogProps> = (props) => {

    const { open, onCancel, onConfirm } = props;
    const appTheme = useTypedSelector((state) => state.themeReducer.appTheme);

    return(
        <Dialog
            open={open}
            onClose={onCancel}
            aria-labelledby="empty-cart-confirmation-dialog"
            aria-describedby="empty-cart-confirmation-dialog"
            className={`theme--${appTheme} order-payment-validation-dialog`}
        >
            <DialogTitle id="alert-dialog-title" className='order-payment-validation-dialog-title'>
                {t("CARTS.DeleteCart")}
            </DialogTitle>

            <DialogContent className='order-payment-validation-dialog-content'>

                <p>
                    {t("CARTS.DeleteCartConfirm")}
                </p>

            </DialogContent>

            <DialogActions className='order-payment-validation-dialog-actions'>

                <Button className='sedeo-button-text' onClick={onCancel}>
                    {t("BUTTONS.Cancel")}
                </Button>

                <Button className='sedeo-button-rounded validation-button' onClick={onConfirm}>
                    {t("BUTTONS.ConfirmDelete")}
                </Button>

            </DialogActions>
        </Dialog>
    )

}