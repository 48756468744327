import 'i18n';
import { useRouter } from "next/router";
import CartApi from '../../api/carts/CartApi';
import { useCartTooltipPageHandler } from '../../ui/header/cartTooltip/PageHandler';
import { Repository } from '../../ui/header/cartTooltip/PageRepository';
import { PageView } from '../../ui/header/cartTooltip/PageView';

export const CartTooltip = (props: {hideTooltip: () => void}) => {
    
    const router = useRouter();
    const handler = useCartTooltipPageHandler(router, new Repository(new CartApi()), props.hideTooltip)
    
    return (
        <PageView handler={handler}/>
    )
}

