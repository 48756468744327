import { debounce } from "@mui/material";
import { t } from "i18next";
import { NextRouter } from "next/router";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import CartApi from "../../../api/carts/CartApi";
import { groupItemsByCategory } from "../../../api/carts/CartApiDataModel";
import { PricedItem } from "../../../api/models/item";
import { useDialog } from "../../../components/hooks/use-dialog";
import { OrderCartItemViewModel } from "../../../features/order/cart/view-model";
import { EventDataType } from "../../../redux/event/EventDataType";
import { updateCart } from "../../../redux/event/event.actions";
import { openSnackbar } from "../../../redux/snackbar/snackbar.actions";
import { SnackbarTypeEnum } from "../../../redux/snackbar/snackbar.reducer";
import { PAGE_STATUS } from "../../../typing/page-state-types";
import AmplitudeHelper, { EventDataFormDialogStartFromScratchOrigin } from "../../../utils/AmplitudeHelper";
import BugsnagHelper from "../../../utils/BugsnagHelper";
import MarketItemAPIHelper from "../../../utils/api/cms/MarketItemAPIHelper";
import { DEFAULT_API_DEBOUNCE_TIME } from "../../../utils/consts";
import { useTypedSelector } from "../../../utils/use-typed-selector";
import { Repository } from "./PageRepository";
import { CartTooltipState, useCartTooltipState } from "./PageState";

export interface PageHandler {
    cartPriceValue: number;
    state: CartTooltipState;
    isFetchingCart: boolean;
    openConfirmDialog: boolean;
    isCurrentlyShopping: boolean;
    openEventDataFormDialog: boolean;
    currentEvent: EventDataType | null;
    hideTooltip: () => void;
    onEmptyCartConfirm: () => void;
    handleOpenConfirmDialog: () => void;
    handleCancelConfirmDialog: () => void;
    setPageState: (state: CartTooltipState) => void;
    setIsFetchingCart: (isFetchingCart: boolean) => void;
    addItemQuantity: (product: OrderCartItemViewModel) => void;
    removeItemFromCart: (product: OrderCartItemViewModel) => void;
    decreaseItemQuantity: (product: OrderCartItemViewModel) => void;
    setOpenEventDataFormDialog: (openEventDataFormDialog: boolean) => void;
    setItemQuantity: (product: OrderCartItemViewModel, quantity: number) => void;
}

export const useCartTooltipPageHandler = (router: NextRouter, repository: Repository, hideTooltip: () => void): PageHandler => {
    const dispatch = useDispatch()
    const state = useCartTooltipState({status: PAGE_STATUS.LOADING})
    const debounceRefs = useRef(new Map<string, ReturnType<typeof debounce>>()).current;
    const [openConfirmDialog, handleOpenConfirmDialog, handleCancelConfirmDialog] = useDialog();
    
    const cartPriceValue = useTypedSelector((state) => state.eventReducer.currentEvent?.cart ? state.eventReducer.currentEvent.cart.prices.total.exc_tax_total_price : 0)
    const currentEvent = useTypedSelector((state) => state.eventReducer.currentEvent);
    const isCurrentlyShopping = useTypedSelector((state) => state.eventReducer.currentEvent !== null);

    useEffect(() => {
        getMarketItems()
        state.setIsFetchingCart(false)
    }, [currentEvent?.cart?.prices.total.exc_tax_total_price])

    const getMarketItems = async () => {
        const cart = currentEvent?.cart
        try{
            if(cart){
                const marketItems = await MarketItemAPIHelper.getMarketItemsByOwners(cart.products.items.map((item: PricedItem) => item.uuid))
                const organizedItemsByCategory = groupItemsByCategory(cart.products.items, marketItems)
                if(organizedItemsByCategory) state.setPageState({status: PAGE_STATUS.DATA, cartItems: organizedItemsByCategory})
            }
            else{
                state.setPageState({status: PAGE_STATUS.EMPTY_STATE, cartItems: []})
            }
        }
        catch(err) {
            state.setPageState({status: PAGE_STATUS.ERROR, error: t('GLOBALS.NetworkError')})
            BugsnagHelper.notify(new Error("useCartTooltipPageHandler/getMarketItems: " + JSON.stringify(err)))
        }
    }

    const addItemQuantity = async (product: OrderCartItemViewModel) => {
        if(currentEvent?.cart?.id) {
            const newQuantity = product.quantity + 1
            state.setPageState(prevState => {
                if(prevState.status !== PAGE_STATUS.DATA){
                    return prevState
                }
                const cartItems = prevState.cartItems
                for(let i in cartItems){
                    for(let j in cartItems[i].items){
                        if(cartItems[i].items[j].id === product.id){ 
                            cartItems[i].items[j] = product
                            cartItems[i].items[j].quantity = newQuantity
                            break;
                        }
                    }
                }

                return {
                    ...prevState,
                    cartItems: cartItems
                };
            })
            if (!debounceRefs.has(product.id)) {
                debounceRefs.set(product.id, debounce(async (item: OrderCartItemViewModel, newQuantity: number) => {
                    if(currentEvent?.cart) {
                        try{
                            const cartResponse = await repository.setItem(currentEvent.cart.id, item.id, newQuantity)
                            dispatch(updateCart(cartResponse))
                        }
                        catch(err){
                            dispatch(openSnackbar(SnackbarTypeEnum.ERROR, t("GLOBALS.ServerError")))
                        }
                    }
                }, DEFAULT_API_DEBOUNCE_TIME))
            }
            debounceRefs.get(product.id)?.(product, newQuantity);
        }
    }

    const decreaseItemQuantity = async (product: OrderCartItemViewModel) => {
        if(currentEvent?.cart) {
            const newQuantity = product.quantity - 1
            state.setPageState(prevState => {
                if(prevState.status !== PAGE_STATUS.DATA){
                    return prevState
                }
                const cartItems = prevState.cartItems
                for(let i in cartItems){
                    for(let j in cartItems[i].items){
                        if(cartItems[i].items[j].id === product.id){ 
                            cartItems[i].items[j] = product
                            cartItems[i].items[j].quantity = newQuantity
                            break;
                        }
                    }
                }

                return {
                    ...prevState,
                    cartItems: cartItems
                };
            })
            if (!debounceRefs.has(product.id)) {
                debounceRefs.set(product.id, debounce(async (item: OrderCartItemViewModel, newQuantity: number) => {
                    if(currentEvent?.cart) {
                        try{
                            if(newQuantity > 0){
                                const cartResponse = await repository.setItem(currentEvent.cart.id, item.id, newQuantity)
                                dispatch(updateCart(cartResponse))
                            }
                            else{
                                const cartResponse = await repository.removeItem(currentEvent.cart.id, item.id)
                                dispatch(updateCart(cartResponse))
                            }
                        }
                        catch(err){
                            dispatch(openSnackbar(SnackbarTypeEnum.ERROR, t("GLOBALS.ServerError")))
                        }
                    }
                }, DEFAULT_API_DEBOUNCE_TIME))
            }
            debounceRefs.get(product.id)?.(product, newQuantity);
        }
    }

    const setItemQuantity = async (product: OrderCartItemViewModel, quantity: number) => {
        if(!isCurrentlyShopping){
            state.setOpenEventDataFormDialog(true)
            AmplitudeHelper.trackEventStartEventClick(EventDataFormDialogStartFromScratchOrigin.MARKET_SUB_CATEGORY_LIST)
        }else{
            state.setPageState(prevState => {
                if(prevState.status !== PAGE_STATUS.DATA){
                    return prevState
                }
                const cartItems = prevState.cartItems
                for(let i in cartItems){
                    for(let j in cartItems[i].items){
                        if(cartItems[i].items[j].id === product.id){ 
                            cartItems[i].items[j] = product
                            cartItems[i].items[j].quantity = quantity
                            break;
                        }
                    }
                }

                return {
                    ...prevState,
                    cartItems: cartItems
                };
            })
            if (!debounceRefs.has(product.id)) {
                debounceRefs.set(product.id, debounce(async (item: OrderCartItemViewModel, newQuantity: number) => {
                    if(currentEvent?.cart) {
                        try{
                            if(newQuantity > 0){
                                const cartResponse = await repository.setItem(currentEvent.cart.id, item.id, newQuantity)
                                dispatch(updateCart(cartResponse))
                            }
                            else{
                                const cartResponse = await repository.removeItem(currentEvent.cart.id, item.id)
                                dispatch(updateCart(cartResponse))
                            }
                        }
                        catch(err){
                            dispatch(openSnackbar(SnackbarTypeEnum.ERROR, t("GLOBALS.ServerError")))
                        }
                    }
                }, DEFAULT_API_DEBOUNCE_TIME))
            }
            debounceRefs.get(product.id)?.(product, quantity);
        }
    }

    const removeItemFromCart = async (product: OrderCartItemViewModel) => {
        if(currentEvent?.cart){
            const cartResponse = await repository.removeItem(currentEvent.cart.id, product.id)
            dispatch(updateCart(cartResponse))
            if(debounceRefs.has(product.id)) debounceRefs.delete(product.id)
        }
    }

    const onEmptyCartConfirm = async () => {
        if(currentEvent?.cart) {
            hideTooltip()
            handleCancelConfirmDialog()
            const cartApi = new CartApi()
            const cartResponse = await cartApi.clearItems(currentEvent.cart.id)
            dispatch(updateCart(cartResponse))
        }
    }

    return {
        openEventDataFormDialog: state.openEventDataFormDialog,
        isFetchingCart: state.isFetchingCart,
        state: state.pageState,
        isCurrentlyShopping,
        openConfirmDialog,
        cartPriceValue,
        currentEvent,
        hideTooltip,
        addItemQuantity,
        setItemQuantity,
        removeItemFromCart,
        onEmptyCartConfirm,
        decreaseItemQuantity,
        handleOpenConfirmDialog,
        handleCancelConfirmDialog,
        setPageState: state.setPageState,
        setIsFetchingCart: state.setIsFetchingCart,
        setOpenEventDataFormDialog: state.setOpenEventDataFormDialog,
    }
}