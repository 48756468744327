import CartApi from "../../../api/carts/CartApi";
import { CartResponse } from "../../../api/carts/CartApiDataModel";

export class Repository{

    constructor(private readonly cartApi: CartApi) {
    }

    public async setItem(cartId: string, productId: string, quantity: number): Promise<CartResponse> {
        return await this.cartApi.setItem(cartId, productId, {quantity})
    }

    public async removeItem(cartId: string, productId: string): Promise<CartResponse> {
        return await this.cartApi.removeItem(cartId, productId)
    }

}