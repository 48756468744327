import AddressesApi from "../../api/addresses/AddressesApi";
import { GetOrCreateAddress, GetOrCreateAddressResponse } from "../../api/addresses/dto/GetOrCreateAddress";
import { ListVenuesFromAddressResponse } from "../../api/addresses/dto/ListVenuesFromAddress";
import CartApi from "../../api/carts/CartApi";
import { CartResponse } from '../../api/carts/CartApiDataModel';
import { PricedItem } from "../../api/models/item";
import { SpatialDataResult } from "../../typing/types";
import { LocationFormInfo } from "../../ui/eventDataForm/EventDataForm";
import ProductsAPIHelper from "../../utils/api/products/ProductsAPIHelper";

export class PageRepository {

    constructor(private readonly addressesApi: AddressesApi, private readonly cartApi: CartApi) {
    }

    public async createNewCart(locationInfo: LocationFormInfo, addressId: string, venueId: string|undefined): Promise<{cart: CartResponse, spData: SpatialDataResult}>{

        // if the user has selected a venue from the known venues list, create the cart based on that venue.
        // otherwise, create the cart based on the address
        let createCartResponse: GetOrCreateAddressResponse;
        if(venueId){
            createCartResponse = await this.cartApi.createCartFromVenue(venueId)
        }
        else{
            createCartResponse = await this.cartApi.createCartFromAddress(addressId)
        }

        const cart = await this.cartApi.get(createCartResponse.data.id)
        const spData: SpatialDataResult = await ProductsAPIHelper.getSpatialData(locationInfo.lat, locationInfo.lng)
        return {cart, spData}
    }

    public async updateCart(cartId: string, locationInfo: LocationFormInfo, addressId: string, venueId: string|undefined): Promise<{cart: CartResponse, spData: SpatialDataResult}>{

        let updatedCartResponse: CartResponse;
        if(venueId){
            // if a venue was selected, update the cart's venue
            updatedCartResponse = await this.cartApi.updateVenue(cartId, venueId)
        }
        else{
            // if NO venue was selected, update the cart's address instead
            updatedCartResponse = await this.cartApi.updateAddress(cartId, addressId)
        }

        const spData: SpatialDataResult = await ProductsAPIHelper.getSpatialData(locationInfo.lat, locationInfo.lng)
        return {cart: updatedCartResponse, spData}
    }

    public async setCartToUser(cartId: string) {
        await this.cartApi.setUser(cartId)
    }

    public async removeItems(cartId: string, items: PricedItem[]) {
        for (let i = 0; i < items.length; i++) {
            await this.cartApi.removeItem(cartId, items[i].uuid)
        }
    }

    public async getOrCreateAddress(locationInfo: LocationFormInfo): Promise<GetOrCreateAddressResponse> {
        return await this.addressesApi.getOrCreateAddress(this.buildAddressData(locationInfo))
    }

    public async getAddressVenues(addressId: string): Promise<ListVenuesFromAddressResponse> {
        return await this.addressesApi.listVenuesFromAddress(addressId)
    }

    // _________________________________________________________________________________________________________ PRIVATE
    private buildAddressData = (locationInfo: LocationFormInfo): GetOrCreateAddress => {
        return {
            google_place_id: locationInfo.address.google_place_id,
            line1: locationInfo.address.line1,
            line2: locationInfo.address.line2,
            line3: locationInfo.address.line3,
            zip_code: locationInfo.address.zip_code,
            city: locationInfo.address.city,
            coordinates: {
                latitude: locationInfo.lat,
                longitude: locationInfo.lng
            },
        }
    }
}