import DoneIcon from '@mui/icons-material/Done';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import { LoadingButton } from "@mui/lab";
import { Box, Button, Card } from "@mui/material";
import 'i18n';
import { t } from 'i18next';
import { FC, useEffect, useRef, useState } from "react";
import { Address } from "../../api/models/address";
import { PageHandler } from "../../components/eventDataForm/PageHandler";
import { EventDataType } from "../../redux/event/EventDataType";
import { LoadingAnimation } from "../common/LoadingAnimation";
import { GoogleApiLocationForm, GooglePlaceType } from "./location/GoogleApiLocationForm";


export interface ParentClick {
    handleNextClick: () => void
}

export interface LocationFormInfo {
    address: Address;
    addressLabel: string
    googleSelectedPlace: GooglePlaceType|null // For GoogleApiLocationForm only
    lat: number
    lng: number
}

interface EventDataFormProps {
    prePopulatedData: EventDataType|null
    isPreparingCart: boolean
    handler: PageHandler
    onCancel: () => void
}
export const EventDataForm: FC<EventDataFormProps> = (props) => {

    const {prePopulatedData, handler, onCancel, isPreparingCart} = props
    const [hasSelectedPlace, setHasSelectedPlace] = useState<boolean>(prePopulatedData != null)
    const [locationRequiredError, setLocationRequiredError] = useState(false)

    const locationRef = useRef<ParentClick>(null)

    useEffect(() => {
        if(prePopulatedData?.googleSelectedPlace?.place_id === handler.locationFormInfo?.googleSelectedPlace?.place_id){
            handler.getKnownLocationVenues(handler.locationFormInfo)
        }
    }, [prePopulatedData])

    const handleNextButton = () => {
        if(handler.locationFormInfo && handler.selectedAddress){
            handler.setLocationError(null)
            handler.createOrUpdateEvent(handler.locationFormInfo, handler.selectedAddress.data.id, handler.selectedVenue?.id)
        }
        else{
            handler.setLocationError(t('EVENT.LocationError'))
        }
    }

    useEffect(() => {
        if(hasSelectedPlace){
            const nextBtn = document.getElementById('next-btn');
            nextBtn?.focus();
        }
    }, [hasSelectedPlace])

    useEffect(() => {
        setTimeout(() => {
            handler.setIsLoadingVenues(false)
        }, 1000);
    }, [handler.knownVenues])

    const getContent = () => {
        return (
            <div className={handler.locationError !== null ? 'google-api-location-form-has-error' : ''}>
                <GoogleApiLocationForm
                    googleApiKey={process.env.NEXT_PUBLIC_GLE_API!!}
                    prePopulatedData={handler.locationFormInfo}
                    setHasSelectedPlace={setHasSelectedPlace}
                    locationError={handler.locationError}
                    setLocationError={handler.setLocationError}
                    locationRequiredError={locationRequiredError}
                    setLocationRequiredError={setLocationRequiredError}
                    ref={locationRef}
                    label={t('MARKET.EnterEventAddress')}
                    requiredErrorText={t('MARKET.PlsEnterAddress')}
                    onLocationSelected={handler.getKnownLocationVenues}
                />
                {handler.locationError && !locationRequiredError && <p className='error-info'>{handler.locationError}</p>}
            </div>
        );
    }

    const renderKnownVenues = () => {
        return handler.isLoadingVenues ?
            <div className='known-venues-loading'>
                <LoadingAnimation/>
            </div>
            :
            handler.knownVenues.length > 0 &&
                <div className="known-venues-list-container">
                    <p>{t('EVENT.DetectedVenues')}</p>

                    <div className='venues-list'>

                        <div className={`location-search-line venue-line hoverable-bg clickable ${handler.selectedVenue === null ? 'selected-venue' : ''}`}
                            onClick={() => handler.setSelectedVenue(null)}>
                            <Box
                                component={LocationCityIcon}
                                className='location-icon'
                            />
                            <span className='no-venue-line'>
                                Je ne veux pas préciser la salle de réception
                            </span>
                            {handler.selectedVenue === null && <div className='done-icon'><DoneIcon/></div>}
                        </div>

                        {
                            handler.knownVenues.map((venue, index) =>
                                <div key={index}
                                    className={`location-search-line venue-line hoverable-bg clickable ${handler.selectedVenue?.id === venue.id ? 'selected-venue' : ''}`}
                                    onClick={() => handler.setSelectedVenue(venue)}>
                                    <Box
                                        component={LocationCityIcon}
                                        className='location-icon'
                                    />
                                    <span>
                                        {venue.name}
                                    </span>
                                    {handler.selectedVenue?.id === venue.id && <div className='done-icon'><DoneIcon/></div>}
                                </div>
                            )
                        }
                    </div>

                </div>
    }

    return (
        <>
            <Card className="event-stepper-container" variant="outlined">
                {/* <p className="enter-location-info">{t('EVENT.PleaseEnterEventLocation')}</p> */}
                {getContent()}
            </Card>

            {renderKnownVenues()}

            <div className="dialog-buttons">

                {/* Cancel button */}
                <Button
                    color="secondary"
                    className="text-button"
                    onClick={onCancel}
                    variant="text"
                    disabled={isPreparingCart}
                >
                    {t('BUTTONS.Cancel')}
                </Button>

                {/* Next button */}
                <LoadingButton
                    id='next-btn'
                    color="secondary"
                    className="next-button"
                    onClick={handleNextButton}
                    variant="outlined"
                    loading={isPreparingCart}
                    disabled={!hasSelectedPlace || isPreparingCart}
                >
                    {!isPreparingCart && t('BUTTONS.Next')}
                </LoadingButton>
            </div>

        </>
    );
}
