import {Add, Remove} from "@mui/icons-material";
import {Button, TextField} from "@mui/material";
import {useEffect, useState} from "react";


export const QuantitySelector = (props: {
        quantity: number, 
        decreaseQuantity: () => void, 
        setQuantity: (quantity: number) => void
        increaseQuantity: () => void
    }) => {

    const { quantity, decreaseQuantity, setQuantity, increaseQuantity } = props;
    const [productQuantity, setProductQuantity] = useState(0);

    useEffect(() => {
        setProductQuantity(quantity)
    }, [quantity])

    return (
        <div className="quantity-container">
            <div className='quantity-selector'>

                <Button
                    variant="contained"
                    onClick={() => decreaseQuantity()}
                    disabled={productQuantity < 1}
                    aria-label={'moins'}
                >
                    <Remove fontSize="small" />
                </Button>

                <TextField className='quantity-selector-input'
                    type="number"
                    variant="outlined"
                   inputProps={{
                       "aria-label": "quantité",
                   }}
                    // Prevent scroll changing number input. See https://github.com/mui/material-ui/issues/7960
                    onWheel={e => e.target instanceof HTMLElement && e.target.blur()} 
                    onBlur={(event) => {
                        setQuantity(parseInt(event.target.value) >= 0 ? parseInt(event.target.value) : 0)
                    }}
                    onChange={(event) => {
                        setQuantity(parseInt(event.target.value) >= 0 ? parseInt(event.target.value) : 0)
                    }}
                    value={productQuantity ? productQuantity.toString() : '0'}
                    />
                
                <Button
                    variant="contained"
                    onClick={() => increaseQuantity()}
                    aria-label={'plus'}
                >
                    <Add fontSize="small" />
                </Button>

            </div>
        </div>
    )
}