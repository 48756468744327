import 'i18n';
import { t } from "i18next";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { GetOrCreateAddressResponse } from '../../api/addresses/dto/GetOrCreateAddress';
import { ListVenuesFromAddressResponse } from '../../api/addresses/dto/ListVenuesFromAddress';
import { MinimizedVenue } from '../../api/models/venues';
import { EventDataType } from "../../redux/event/EventDataType";
import { setNewEvent, updateEventInfo } from "../../redux/event/event.actions";
import { THEME } from "../../redux/event/theme.reducer";
import { USER_CONNECTION_STATUS } from "../../redux/session/session.reducer";
import { openSnackbar } from "../../redux/snackbar/snackbar.actions";
import { SnackbarTypeEnum } from "../../redux/snackbar/snackbar.reducer";
import { SpatialDataResult } from "../../typing/types";
import { LocationFormInfo } from "../../ui/eventDataForm/EventDataForm";
import AddressHelper from '../../utils/AddressHelper';
import AmplitudeHelper, { OrderingStep } from '../../utils/AmplitudeHelper';
import BugsnagHelper from '../../utils/BugsnagHelper';
import CartDiffHelper, { CartDiffResult } from "../../utils/api/cart/CartDiffHelper";
import { useTypedSelector } from "../../utils/use-typed-selector";
import { PageRepository } from "./PageRepository";
import { usePageState } from "./PageState";


export interface PageHandler {
    appTheme: THEME;
    isPreparingCart: boolean
    openConfirmDialog: boolean
    currentEvent: EventDataType | null
    cartDifferences: CartDiffResult | undefined
    eventDataToUpdate: EventDataType | undefined
    spatialDataResult: SpatialDataResult | undefined
    locationFormInfo: LocationFormInfo | undefined
    knownVenues: MinimizedVenue[]
    selectedVenue: MinimizedVenue|null
    selectedAddress: GetOrCreateAddressResponse | undefined
    isLoadingVenues: boolean
    locationError: string | null
    setLocationError: (locationError: string | null) => void
    setIsLoadingVenues: (v: boolean) => void
    createOrUpdateEvent: (locationInfo: LocationFormInfo, addressId: string, venueId: string|undefined) => void
    setSelectedAddress: (address: GetOrCreateAddressResponse) => void
    setKnownVenues: (knownVenues: MinimizedVenue[]) => void
    setSelectedVenue: (selectedVenue: MinimizedVenue|null) => void
    getKnownLocationVenues: (info: LocationFormInfo | undefined) => void
    handleCancelDialog: () => void;
    handleDataConfirmation: () => void;
    setIsPreparingCart: (v: boolean) => void;
    getVenues: (addressId: string) => Promise<ListVenuesFromAddressResponse>;
    getOrCreateAddress: (locationFormInfo: LocationFormInfo) => Promise<GetOrCreateAddressResponse>;
}

export const usePageHandler = (onSuccess: (spToken: string, defaultMarketRedirectionUrl: string) => void, repository: PageRepository): PageHandler => {

    const currentEvent = useTypedSelector((state) => state.eventReducer.currentEvent);
    const userSession = useTypedSelector((state) => state.sessionReducer.userSession);
    const appTheme = useTypedSelector((state) => state.themeReducer.appTheme);
    const state = usePageState()
    const dispatch = useDispatch()


    useEffect(() => {
        if(currentEvent?.cart) {
            state.setLocationFormInfo({
                address: currentEvent.cart.venue_address.address,
                addressLabel: AddressHelper.getReadableNormalAddress(currentEvent.cart.venue_address.address),
                googleSelectedPlace: currentEvent.googleSelectedPlace,
                lat: currentEvent.cart.venue_address.address.coordinates.latitude,
                lng: currentEvent.cart.venue_address.address.coordinates.longitude
            })
        }
    }, [currentEvent]);

    const getKnownLocationVenues = async (info: LocationFormInfo | undefined) => {
        state.setLocationFormInfo(info)
        AmplitudeHelper.trackEventDataFormDialogLocationAdded()
        if(info) {
            state.setIsLoadingVenues(true)
            setTimeout(async () => {
                try{
                    const address: GetOrCreateAddressResponse = await getOrCreateAddress(info);
                    state.setSelectedAddress(address);
                    const venues: MinimizedVenue[] = (await getVenues(address.data.id)).data;
                    state.setKnownVenues(venues);
                    setTimeout(() => {
                        state.setIsLoadingVenues(false)
                    }, 1000)
                }
                catch(error){
                    BugsnagHelper.notify(new Error("eventDataForm/getKnownLocationVenues/"+ JSON.stringify(info) +' | '  + JSON.stringify(error)))
                    console.error('error:', error)
                    state.setIsLoadingVenues(false)
                    state.setSelectedVenue(null)
                    state.setKnownVenues([])
                    state.setLocationError(t('EVENT.LocationError'))
                }
            }, 500)
        }
        else{
            state.setIsLoadingVenues(false)
            state.setSelectedVenue(null)
            state.setKnownVenues([])
        }
    }

    const handleDataConfirmation = () => {
        state.setIsPreparingCart(false)

        if(state.eventDataToUpdate){

            // Remove unavailable items
            if(state.cartDifferences?.itemsNotAvailable){
                repository.removeItems(state.eventDataToUpdate.cart.id, state.cartDifferences.itemsNotAvailable)
            }

            dispatch(updateEventInfo(state.eventDataToUpdate))
            state.handleCancelConfirmDialog()

            onSuccess(state.eventDataToUpdate.SPToken, state.eventDataToUpdate.defaultMarketRedirectionUrl)
        }
    }

    const handleCancelDialog = () => {
        state.setIsPreparingCart(false)
        state.handleCancelConfirmDialog()
    }

    const handleError = (message: string) => {
        state.setIsPreparingCart(false)
        dispatch(openSnackbar(SnackbarTypeEnum.ERROR, t("GLOBALS.ServerError")))
        BugsnagHelper.captureMessage(message)
    }

    // __________________________________________________________________________________________________________PRIVATE

    const createOrUpdateEvent = async (locationInfo: LocationFormInfo, addressId: string, venueId: string|undefined) => {

        state.setIsPreparingCart(true)

        if(!currentEvent) {
            // See _app
            if(window.gtag_report_conversion) {
                window.gtag_report_conversion('/market')
            }

            try {
                const {cart, spData} = await repository.createNewCart(locationInfo, addressId, venueId)
                if(userSession.status === USER_CONNECTION_STATUS.LOGGED_IN){
                    await repository.setCartToUser(cart.id)
                }
                state.setIsPreparingCart(false)
                dispatch(setNewEvent(cart, locationInfo.googleSelectedPlace, spData.spatialToken, spData.defaultMarketRedirectionUrl))
                AmplitudeHelper.trackEventDataFormDialogValidated(locationInfo.lat, locationInfo.lng, locationInfo.addressLabel)

                onSuccess(spData.spatialToken, spData.defaultMarketRedirectionUrl)

            } catch (error) {
                handleError("EventDataFormDialog Error creating new cart: " + JSON.stringify(error))
            }
        }
        else{
            try{
                const {cart, spData} = await repository.updateCart(currentEvent.cart.id, locationInfo, addressId, venueId)

                const eventDataToUpdate: EventDataType = {
                    cart: cart,
                    googleSelectedPlace: locationInfo.googleSelectedPlace,
                    maxOrderingStep: OrderingStep.NONE,
                    SPToken: spData.spatialToken,
                    defaultMarketRedirectionUrl: spData.defaultMarketRedirectionUrl
                }
                if(cart.products.items.length === 0){
                    dispatch(updateEventInfo(eventDataToUpdate))
                    onSuccess(spData.spatialToken, spData.defaultMarketRedirectionUrl)
                    state.setIsPreparingCart(false)
                }
                else{
                    const cartDifferences = await CartDiffHelper.getDifference(cart.products.items, spData.spatialToken)
                    state.setCartDifferences(cartDifferences)
                    state.setEventDataToUpdate(eventDataToUpdate)
                    if(cartDifferences.availableItemsWithPriceChange.length > 0 || cartDifferences.itemsNotAvailable.length > 0){
                        state.handleOpenConfirmDialog()
                    }
                    else{
                        handleDataConfirmation()
                    }
                }

            } catch (error) {
                handleError("EventDataFormDialog Error updating cart " + JSON.stringify(error))
            }
        }
    }


    const getVenues = async (addressId: string): Promise<ListVenuesFromAddressResponse> => {
        return repository.getAddressVenues(addressId)
    }
    const getOrCreateAddress = async (locationInfo: LocationFormInfo): Promise<GetOrCreateAddressResponse> => {
        return await repository.getOrCreateAddress(locationInfo)
    }

    return {
        appTheme,
        currentEvent,
        knownVenues: state.knownVenues,
        locationError: state.locationError,
        selectedVenue: state.selectedVenue,
        isPreparingCart: state.isPreparingCart,
        cartDifferences: state.cartDifferences,
        selectedAddress: state.selectedAddress,
        isLoadingVenues: state.isLoadingVenues,
        locationFormInfo: state.locationFormInfo,
        openConfirmDialog: state.openConfirmDialog,
        eventDataToUpdate: state.eventDataToUpdate,
        spatialDataResult: state.spatialDataResult,
        getVenues,
        handleCancelDialog,
        getOrCreateAddress,
        createOrUpdateEvent,
        getKnownLocationVenues,
        handleDataConfirmation,
        setKnownVenues: state.setKnownVenues,
        setLocationError: state.setLocationError,
        setSelectedVenue: state.setSelectedVenue,
        setIsLoadingVenues: state.setIsLoadingVenues,
        setSelectedAddress: state.setSelectedAddress,
        setIsPreparingCart: state.setIsPreparingCart,
    };
}