import { East } from "@mui/icons-material"
import EventIcon from '@mui/icons-material/Event'
import { Button, Divider } from "@mui/material"
import { t } from "i18next"
import router from "next/router"
import { FC } from "react"
import { OrderCartContentByCategoryViewModel } from "../../../features/order/cart/view-model"
import { EmptyCartConfirmationDialog } from "../../../features/order/validation/ui/EmptyCartConfirmationDialog"
import { PAGE_STATUS } from "../../../typing/page-state-types"
import AmplitudeHelper, { EventDataFormDialogStartFromScratchOrigin, MarketItemClickOrigin, SeeCartRecapOrigin } from "../../../utils/AmplitudeHelper"
import CurrencyHelper from "../../../utils/CurrencyHelper"
import RoutesHelper, { ROUTES } from "../../../utils/consts"
import { CartItemLayout } from "../../SideCart/CartItemLayout"
import { ErrorPage } from "../../common/ErrorPage"
import { LoadingAnimation } from "../../common/LoadingAnimation"
import { EventDataFormDialog } from "../../eventDataForm/EventDataFormDialog"
import { PageHandler } from "./PageHandler"


interface PageViewProps {
    handler: PageHandler
}

export const PageView: FC<PageViewProps> = (props) => {

    const {handler} = props

    const renderCreateEvent = () => {
        return <div className='invite-to-create-event'>
            <p>*Pour pouvoir continuer vos achats, veuillez d&apos;abord sélectionner une date et un lieu pour votre évènement</p>
            <div>
                <Button variant="contained"
                    className="sedeo-button-rounded"
                    onClick={() => {
                        handler.setOpenEventDataFormDialog(true)
                        AmplitudeHelper.trackEventStartEventClick(EventDataFormDialogStartFromScratchOrigin.SIDE_CART)
                    }}>
                    {t("MARKET.CreateEvent")}
                    <EventIcon className='event-icon'/>
                </Button>
            </div>
        </div>
    }

    const renderGoToCatalog = () => {
        return <div className='empty-cart-go-to-catalog'>
                <p>{t('GLOBALS.ThinkOf')} <span 
                    className='sedeo-main-color-text' 
                    onClick={() => {
                        handler.hideTooltip()
                        router.push(RoutesHelper.getDefaultCatalogPage())
                    }}
                    >
                    {t('BUTTONS.ViewOurCatalog')} <East className='next-arrow' fontSize="small"/></span></p>
            </div>
    }

    const renderPriceDetails = () => {
        return (
            <div className='price-details-cart-tooltip'>
                <Divider sx={{m: 'auto', py: 1, bordercolor: 'text.primary'}}/>
                
                <div className='cart-price-line'>
                    <h4>{t("ORDERS.Receipt.TotalEquipmentHT")} : {
                        handler.currentEvent?.cart?.prices && !handler.isFetchingCart ?
                            <span className='total-amount'>{CurrencyHelper.convertCentimesToEuros(handler.currentEvent.cart.prices.products.exc_tax_total_price).toFixed(2)}€</span>
                            :
                            <LoadingAnimation/>
                    }</h4>
                </div>

                <div className='validate-cart-button-container'>
                    <Button
                        variant="outlined"
                        className='sedeo-button-rounded validate-button'
                        onClick={() => {
                            if(handler.currentEvent?.cart && handler.currentEvent?.cart?.products.items.length > 0){
                                AmplitudeHelper.trackSeeCartRecap(SeeCartRecapOrigin.SIDE_MENU, handler.cartPriceValue, handler.currentEvent?.cart.products.items)
                                router.push(ROUTES.ORDERS.CART)
                                handler.hideTooltip()
                            }
                        }}
                        disabled={handler.currentEvent?.cart?.prices === null || handler.currentEvent?.cart?.prices.total.exc_tax_total_price === 0 || handler.isFetchingCart}
                    >
                        {t("BUTTONS.ValidateCart")}
                    </Button>
                </div>

                <div className='empty-cart-button-container'>
                    <Button
                        variant="text"
                        className='empty-cart-button text-button'
                        onClick={() => {
                            handler.handleOpenConfirmDialog()
                        }}
                        disabled={handler.isFetchingCart}
                    >
                        {t("CARTS.EmptyMyCart")}
                    </Button>
                </div>

                <p className='cart-line-notice'>
                    <strong>*</strong> {t('CARTS.ShippingToBeDetermined')}
                </p>

            </div>
        )
    }

    const renderContent = (cartItems: OrderCartContentByCategoryViewModel[]) => {
        return (
            <>
                <div className='cart-layout'>
                
                    <div className='cart-body'>
                        {
                            cartItems.length > 0 ?
                                cartItems.map((category: OrderCartContentByCategoryViewModel) => {
                                    return category.items.map((item) => {
                                        return <CartItemLayout key={item.id}
                                                    cartItem={item}
                                                    decreaseItemQuantity={(item) => handler.decreaseItemQuantity(item)}
                                                    setItemQuantity={(item, quantity) => handler.setItemQuantity(item, quantity)}
                                                    increaseItemQuantity={(item) => handler.addItemQuantity(item)}
                                                    removeItemFromCart={(item) => handler.removeItemFromCart(item)}
                                                    onClick={() => {
                                                        AmplitudeHelper.trackMarketItemClick(item.id, MarketItemClickOrigin.SIDE_CART)
                                                    }}/>
                                    })
                                })
                                :
                                <h4 className='empty-cart-info'>
                                    {t("CARTS.EmptyCart")}
                                </h4>
                        }
                    </div>

                    <div className='cart-footer'>
                        {
                            handler.isCurrentlyShopping ?
                                cartItems && (
                                    cartItems.length > 0 ?
                                        renderPriceDetails()
                                        :
                                        renderGoToCatalog()
                                    )
                                :
                                renderCreateEvent()
                        }
                    </div>
                    
                </div>

                <EventDataFormDialog
                    open={handler.openEventDataFormDialog}
                    onCancel={() => handler.setOpenEventDataFormDialog(false)}
                    onSuccess={(spatialToken: string, defaultMarketRedirectionUrl: string) => {
                        handler.setOpenEventDataFormDialog(false)
                        handler.hideTooltip()
                        // If user isn't in the catalog, redirect him to the default catalog page
                        if(router.isReady && !router.asPath.includes('/catalogue')) {
                            router.push(defaultMarketRedirectionUrl)
                        }
                    }}
                />

                <EmptyCartConfirmationDialog
                    open={handler.openConfirmDialog}
                    onConfirm={handler.onEmptyCartConfirm}
                    onCancel={handler.handleCancelConfirmDialog}
                />
            </>
        )
    }

    const renderSwitch = () => {
        switch (handler.state.status) {
            case PAGE_STATUS.ERROR:
                return <ErrorPage error={handler.state.error ?? t("CATEGORY.EmptyState")}/>
            case PAGE_STATUS.LOADING:
                return <LoadingAnimation/>
            case PAGE_STATUS.EMPTY_STATE:
                return renderContent([])
            case PAGE_STATUS.DATA:
                return renderContent(handler.state.cartItems)
        }
    }

    return renderSwitch()
}

