import { useState } from "react";
import { OrderCartContentByCategoryViewModel } from "../../../features/order/cart/view-model";
import { PAGE_STATUS } from "../../../typing/page-state-types";

interface CartTooltipLoadingState {
    status: PAGE_STATUS.LOADING;
}

interface CartTooltipDataState{
    status: PAGE_STATUS.DATA;
    cartItems: OrderCartContentByCategoryViewModel[]
}

interface CartTooltipEmptyState{
    status: PAGE_STATUS.EMPTY_STATE;
    cartItems: OrderCartContentByCategoryViewModel[]
}

interface CartTooltipErrorState {
    status: PAGE_STATUS.ERROR;
    error: string;
}

export type CartTooltipState = CartTooltipLoadingState | CartTooltipDataState | CartTooltipErrorState | CartTooltipEmptyState

export const useCartTooltipState = (initialState: CartTooltipState) => {
    const [pageState, setPageState] = useState<CartTooltipState>(initialState);
    const [openEventDataFormDialog, setOpenEventDataFormDialog] = useState(false);
    const [isFetchingCart, setIsFetchingCart] = useState(false)

    return {
        pageState, setPageState,
        isFetchingCart, setIsFetchingCart,
        openEventDataFormDialog, setOpenEventDataFormDialog,
    }
}