import { Dialog } from "@mui/material";
import 'i18n';
import { t } from 'i18next';
import { FC } from 'react';
import AddressesApi from "../../api/addresses/AddressesApi";
import CartApi from "../../api/carts/CartApi";
import { usePageHandler } from "../../components/eventDataForm/PageHandler";
import { PageRepository } from "../../components/eventDataForm/PageRepository";
import { EventDataForm } from "./EventDataForm";
import { EventDataFormUpdateConfirmationDialog } from "./EventDataFormUpdateConfirmationDialog";


export enum EventDataFormType {
    NEW_EVENT, // Use this if you want to reset the user's cart when validating the dialog
    DATA_EXISTING// Use this if you want to try to keep the user's cart when validating the dialog. //TODO logic will be implemented when multi-renter is done
}

interface EventDataFormProps {
    open: boolean;
    onCancel: () => void;
    onSuccess: (spatialToken: string, defaultMarketRedirectionUrl: string) => void;
}

export const EventDataFormDialog: FC<EventDataFormProps> = (props) => {

    const {open, onCancel, onSuccess, ...other} = props;
    const handler = usePageHandler(onSuccess, new PageRepository(new AddressesApi(), new CartApi()))

    return (
        <Dialog
            className='event-data-form-dialog-container'
            onClose={onCancel}
            open={open}
            {...other}
        >
            <div className={`event-data-form-dialog theme--${handler.appTheme}`}>
                <h2 className='event-data-form-dialog-title'>
                    {t("ORDERS.Overview.Infos")}
                </h2>

                <div className='dialog-content'>
                    <EventDataForm
                        handler={handler}
                        prePopulatedData={handler.currentEvent}
                        onCancel={() => {
                            onCancel()
                            handler.handleCancelDialog()
                        }}
                        isPreparingCart={handler.isPreparingCart}
                    />
                </div>

                <EventDataFormUpdateConfirmationDialog
                    open={handler.openConfirmDialog}
                    title={t("ORDERS.Modification.ValidateChange")}
                    descriptions={[
                        t("ORDERS.Modification.ChangeNote1"),
                        t("ORDERS.Modification.ChangeNote2")
                    ]}
                    changedItems={handler.cartDifferences}
                    onConfirm={handler.handleDataConfirmation}
                    onCancel={handler.handleCancelDialog}
                />
            </div>
        </Dialog>
    );
};
