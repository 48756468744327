import Image from 'next/image';
import { useState } from "react";
import 'react-lazy-load-image-component/src/effects/blur.css';
import sedeoImageLoader from "../../utils/imageLoader";
import { NoImageIcon } from './NoImageIcon';

interface LazyImageProps {
    src: string,
    wrapperClassName?: string,
    imageClassName?: string,
    width?: number,
    height?: number,
    alt: string,
    lazy?: boolean,
    quality?: number,
    useLoader?: boolean
}

export const LazyImage: React.FC<LazyImageProps> = (props) => {
    
    const {src, wrapperClassName, imageClassName, width, height, lazy, alt, quality, useLoader} = props;
    const [loadingError, setLoadingError] = useState(false);
    const [isImgLoading, setIsImgLoading] = useState(false);

    return (
        <div className={`lazy-loaded-image ${wrapperClassName ? wrapperClassName : ''} ${isImgLoading ? 'loading-bg' : ''} ${loadingError ? 'error-placeholder-image' : ''}`}>
            {
                loadingError ?
                    <NoImageIcon/>
                    :
                    <Image
                        itemType='image/webp'
                        src={src}
                        className={imageClassName}
                        loader={() => useLoader === false ? src : sedeoImageLoader({src, width: width ? width : 350, height : height ?? width, quality})}
                        loading={lazy === false ? 'eager' : 'lazy'} 
                        alt={alt} 
                        width={width ? width : 350} 
                        height={height ? height : 350}
                        onLoadStart={() => {setIsImgLoading(true); setLoadingError(false)}}
                        onLoadingComplete={() => {setIsImgLoading(false); setLoadingError(false)}}
                        onError={() => {setIsImgLoading(false); setLoadingError(true)}}
                    />
            }
        </div>
    );
}
