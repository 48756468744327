import WarningIcon from '@mui/icons-material/Warning';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import 'i18n';
import { t } from 'i18next';
import { FC } from "react";
import { useTypedSelector } from '../../utils/use-typed-selector';
import { CartDiffResult } from '../../utils/api/cart/CartDiffHelper';
import { LazyImage } from '../common/LazyImage';


interface EventDataFormUpdateConfirmationDialogProps {
    open: boolean;
    title: string;
    descriptions: string[];
    changedItems: CartDiffResult | undefined;
    onCancel: () => void;
    onConfirm: () => void;
}

export const EventDataFormUpdateConfirmationDialog: FC<EventDataFormUpdateConfirmationDialogProps> = (props) => {

    const { open, title, descriptions, changedItems, onCancel, onConfirm } = props;
    const appTheme = useTypedSelector((state) => state.themeReducer.appTheme);

    const renderChangedProductsList = () => {
        return <div className='changed-items-list'>
            {
                changedItems?.availableItemsWithPriceChange.map((item, index) => {
                    return <div key={index} className='changed-item'>
                        <div className='changed-item-image'>
                            <LazyImage width={80} height={80} src={item.item.images ? item.item.images[0].file_key : ''} alt={item.item.name}/>
                        </div>
                        <div className='changed-item-description'>
                            <h2 className='name'>{item.item.name}</h2>
                            <h4 className='old-price'>{item.oldPricePerUnit.toFixed(2)}€</h4>
                            <h2 className='new-price'>{item.newPricePerUnit.toFixed(2)}€</h2><span>/unité</span>
                        </div>
                    </div>
                })
            }
            {
                changedItems?.itemsNotAvailable.map((item, index) => {
                    return <div key={index} className='changed-item'>
                        <div className='changed-item-image'>
                            <LazyImage width={80} height={80} src={item.images ? item.images[0].file_key : ''} alt={item.name}/>
                        </div>
                        <div className='changed-item-description'>
                            <h2 className='unavailable-item-name'>{item.name}</h2>
                            <h4 className='unavailable-item-notice'>indisponible pour cette région</h4>
                        </div>
                    </div>
                })
            }
            </div>
    }

    return(
        <Dialog
            open={open}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={`event-modification-confirmation-dialog theme--${appTheme}`}
        >
            <DialogTitle className='dialog-title' id="alert-dialog-title">
                {/* {} */}
                {title}
            </DialogTitle>

            <DialogContent className='dialog-content'>

                {/* <p>{t("ORDERS.Modification.ChangeNote1")}</p>

                <p>{t("ORDERS.Modification.ChangeNote2")}</p> */}

                {descriptions?.map((note, index) => <p key={index}>{note}</p>)}

                <div className="product-not-available-for-location-wrapper">
                    <div className='product-not-available-for-location-text'>
                        <WarningIcon/>
                        <p>{t('ORDERS.Modification.ProductsWillChange')}</p>
                    </div>
                    
                    {renderChangedProductsList()}
                </div>

            </DialogContent>

            <DialogActions className='dialog-actions'>

                <Button variant="text"
                    className="sedeo-button-text"
                    onClick={onCancel}>
                    {t('BUTTONS.Cancel')}
                </Button>

                <Button variant="contained"
                    className="sedeo-button-rounded"
                    onClick={onConfirm}>
                    {t("BUTTONS.Understood")}
                </Button>

            </DialogActions>
        </Dialog>
    )

}