import { DateTime } from "luxon";
import { OrderCartContentByCategoryViewModel, OrderCartItemViewModel, OrderCartViewModel } from "../../features/order/cart/view-model";
import { MarketItem } from "../../typing/types";
import CurrencyHelper from "../../utils/CurrencyHelper";
import RoutesHelper from "../../utils/consts";
import { Address, BillingAddress, Details, OtherDetails, VenueAddress } from "../models/address";
import { EventContact, EventContactWithBool } from "../models/contact";
import { DateRange, TimeSlot, TransportSchedule } from "../models/datetime";
import { Coordinates } from "../models/geolocation";
import { PricedItem } from "../models/item";
import { MinimizedPurchaseDiscount, Price, PurchasePrices } from "../models/price";


// ________________________________________ Get Cart REQUEST

// ________________________________________ Get Cart RESPONSE
// this is what we get exactly from the backend
export interface CartApiResponse {
    data: CartResponse
}

export interface CartResponse {
    id: string;
    coefficient: number;
    name: string | null;
    billing_address: Address;
    discounts: MinimizedPurchaseDiscount[];
    event_contact: EventContact;
    prices: PurchasePrices;
    products: {
        items: PricedItem[];
        discount_price: Price;
    }
    transport_schedule: TransportSchedule;
    details: OtherDetails;
    venue_address: VenueAddress;
}


// ________________________________________ Set Handling REQUEST
export interface SetHandlingRequest {
    curbside: boolean;
    floor: number;
    handling_distance: number;
    has_elevator: boolean;
    has_flat_access: boolean;
}

// ________________________________________ Set Venue Details REQUEST
export interface SetVenueDetailsRequest {
    venue_name: string;
    room_name: string;
    digicode: string;
    building_name: string;
    licence_plate_required: boolean;
}


// ________________________________________ Update Cart REQUEST
export interface UpdateCartRequest {
    position: Coordinates;
    address: Address;
    date_range: DateRange;
}


// ________________________________________ Set Information REQUEST
export interface SetInformationsRequest {
    billing_address: BillingAddress;
    event_contact: EventContactWithBool;
    details: Details
}


// ________________________________________ Set Item REQUEST
export interface SetItemRequest {
    quantity: number
}


// ________________________________________ Set Transport Schedule REQUEST
export interface SetTransportScheduleRequest {
    delivery: TimeSlot;
    retrieval: TimeSlot;
}

// ________________________________________ Initiate Order Response
export interface InitiateOrderResponse {
    id: string;
}


export const convertCartResponseToOrderCartViewModel = (response: CartResponse, marketItems: MarketItem[]): OrderCartViewModel =>{
    return {
        cartId: response.id,
        startDate: DateTime.fromISO(response.transport_schedule.delivery.start),
        endDate: DateTime.fromISO(response.transport_schedule.retrieval.start),
        excTaxProductPriceWithoutCoefficient: CurrencyHelper.convertCentimesToEuros(response.products.items.reduce((sum, next) => sum + next.exc_tax_total_price, 0)),
        coefficient: response.coefficient,
        cartContent: groupItemsByCategory(response.products.items, marketItems),
        discountCodes: response.discounts,
        productDiscount: response.prices.products.discount
    }
}

export const  groupItemsByCategory = (pricedItems: PricedItem[], marketItems: MarketItem[]): OrderCartContentByCategoryViewModel[] => {
    const groupedByCategory = new Map<string, OrderCartItemViewModel[]>();

    pricedItems.forEach(item => {
        const marketItem = marketItems.find(marketItem => marketItem.catalogItemUuid ===item.uuid)!
        const category = marketItem.content.marketSubCategorySlug ?? 'Unknown';

        const orderItem: OrderCartItemViewModel = {
            id: item.uuid,
            name: item.name,
            images: item.images,
            url: RoutesHelper.getProductRouteByMarketItem(marketItem),
            quantity: item.quantity,
            excTaxProductUnitPrice: CurrencyHelper.convertCentimesToEuros(item.exc_tax_unit_price),
            excTaxProductTotalPrice: CurrencyHelper.convertCentimesToEuros(item.exc_tax_total_price),
        };

        if (!groupedByCategory.has(category)) {
            groupedByCategory.set(category, []);
        }
        groupedByCategory.get(category)?.push(orderItem);
    });

    return Array.from(groupedByCategory, ([categoryName, items]) => ({
        categoryName,
        items
    }));
}