import {AddressMsg} from "../../proto_generated/type/address_pb";


// Type taken from https://github.com/udayratan/parse-google-address/blob/master/index.js
class GoogleAddress {
    postalCode?: string;
    country?: string;
    administrative_area_level_1?: string;
    administrative_area_level_2?: string;
    administrative_area_level_3?: string;
    locality?: string;
    sublocality_level_1?: string;
    sublocality_level_2?: string;
    sublocality_level_3?: string;
    postal_town?: string;
    neighborhood?: string;
    route?: string;
    street_address?: string;
    street_number?: string;
    premise?: string;
    subpremise?: string;
    floor?: string;
}
export default class GoogleApiHelper {
    static transformGoogleAddressComponentToAddressMsg(addressComponents: google.maps.GeocoderAddressComponent[], name: string): AddressMsg {

        let googleAddress = new GoogleAddress()
        for (let i = 0; i < addressComponents.length; i++) {
            const component = addressComponents[i];
            if(component.types.includes("postal_code")) {
                googleAddress.postalCode = component.long_name
            }else if(component.types.includes("country")) {
                googleAddress.country = component.short_name
            }else if(component.types.includes("administrative_area_level_1")) {
                googleAddress.administrative_area_level_1 = component.long_name
            }else if(component.types.includes("administrative_area_level_2")) {
                googleAddress.administrative_area_level_2 = component.long_name
            }else if(component.types.includes("administrative_area_level_3")) {
                googleAddress.administrative_area_level_3 = component.long_name
            }else if(component.types.includes("locality")) {
                googleAddress.locality = component.long_name
            }else if(component.types.includes("sublocality_level_1")) {
                googleAddress.sublocality_level_1 = component.long_name
            }else if(component.types.includes("sublocality_level_2")) {
                googleAddress.sublocality_level_2 = component.long_name
            }else if(component.types.includes("sublocality_level_3")) {
                googleAddress.sublocality_level_3 = component.long_name
            }else if(component.types.includes("postal_town")) {
                googleAddress.postal_town = component.long_name
            }else if(component.types.includes("neighborhood")) {
                googleAddress.neighborhood = component.long_name
            }else if(component.types.includes("route")) {
                googleAddress.route = component.long_name
            }else if(component.types.includes("street_address")) {
                googleAddress.street_address = component.long_name
            }else if(component.types.includes("street_number")) {
                googleAddress.street_number = component.long_name
            }else if(component.types.includes("premise")) {
                googleAddress.premise = component.long_name
            }else if(component.types.includes("subpremise")) {
                googleAddress.subpremise = component.long_name
            }else if(component.types.includes("floor")) {
                googleAddress.floor = component.long_name
            }

        }

        let addressMsg = new AddressMsg()
        addressMsg.setZipcode(googleAddress.postalCode??'')

        let line1 = ''
        if(googleAddress.street_number) line1 += googleAddress.street_number + ' '
        if(googleAddress.route) line1 += googleAddress.route
        if(!googleAddress.street_number && !googleAddress.route && googleAddress.sublocality_level_1) line1 = googleAddress.sublocality_level_1
        if(!line1){
            line1 = name
        }
        addressMsg.setLine1(line1)

        addressMsg.setCity(googleAddress.locality??'')
        if(googleAddress.administrative_area_level_1) {
            addressMsg.setProvince(googleAddress.administrative_area_level_1)
        }
        addressMsg.setCountrycode(googleAddress.country??'')


        if(name){
            addressMsg.setOther(name)
        }
        return addressMsg
    }

}