import { East } from "@mui/icons-material";
import LocationCityIcon from '@mui/icons-material/LocationCity';
import RoomIcon from '@mui/icons-material/Room';
import { Card, Divider, Tooltip } from "@mui/material";
import 'i18n';
import { t } from 'i18next';
import { NextRouter } from "next/router";
import { FC, useEffect, useState } from "react";
import AddressHelper from "../../utils/AddressHelper";
import { useTypedSelector } from "../../utils/use-typed-selector";
import { EventDataFormDialog } from "../eventDataForm/EventDataFormDialog";


interface EventSelectorBarProps{
    isUserInHome: boolean
    screenDownMD: boolean
    SPToken: string|undefined
    router: NextRouter
}

export const EventSelectorBar: FC<EventSelectorBarProps> = (props) => {

    const { isUserInHome, SPToken, screenDownMD, router } = props;
    const appTheme = useTypedSelector((state) => state.themeReducer.appTheme);
    const [openEventDataFormDialog, setOpenEventDataFormDialog] = useState(false);
    const currentEvent = useTypedSelector((state) => state.eventReducer.currentEvent);
    const [eventLabel, setEventLabel] = useState('')
    const [noEventClass, setNoEventClass] = useState('')

    useEffect(() => {
        setEventLabel(currentEvent ? AddressHelper.getReadableAddressFromVenue(currentEvent.cart.venue_address) : (t('EVENT.WhereToShip') ?? "On vous livre où ?"))
        if(!currentEvent){
            setNoEventClass('without-event')
        }
    }, [currentEvent])

    return <> 
        <Card className={`event-info clickable ${noEventClass}`} elevation={1}>

            <Tooltip
                classes={{popper: `theme--${appTheme}`}}
                componentsProps={{tooltip: {className: 'event-selector-tooltip-props'}}}
                title={
                    <div className="event-location-tooltip"
                        onClick={() => setOpenEventDataFormDialog(true)}>
                        <div>
                            {currentEvent &&
                                <div>
                                    {currentEvent.cart.venue_address.venue_details.venue_name && currentEvent.cart.venue_address.venue_details.venue_name !== '' &&
                                        <div className="event-venue-name">
                                            <LocationCityIcon className="icon"/>
                                            <h4 className="event-venue-name">{AddressHelper.getReadableAddressFromVenue(currentEvent.cart.venue_address)}</h4>
                                        </div>
                                    }
                                    <div className="event-address">
                                        <RoomIcon className="icon"/>
                                        <p>{AddressHelper.getReadableNormalAddress(currentEvent.cart.venue_address.address)}</p>
                                    </div>
                                </div>
                            }
                        </div>
                        <p>{currentEvent ? t('EVENT.ChangeEventLocation') : t('EVENT.ChooseEventLocation')} <East className='next-arrow' fontSize="small"/></p>
                    </div>
                }>
                <div className="event-location"
                    onClick={() => setOpenEventDataFormDialog(true)}>
                    <RoomIcon/>
                    {!screenDownMD && eventLabel}
                </div>
            </Tooltip>

            {currentEvent && currentEvent.cart?.products?.items?.length > 0 && isUserInHome &&
                <>
                <Divider orientation="vertical" flexItem/>

                <Tooltip
                    classes={{popper: `theme--${appTheme}`}}
                    componentsProps={{tooltip: {className: 'event-selector-tooltip-props'}}}
                    title={
                        <div className="event-location-tooltip continue-order-tooltip"
                            onClick={() => router.push(currentEvent.defaultMarketRedirectionUrl)}>
                            <h4 className="continue-order-label">{t('HEADER.ContinueOrder')}</h4>
                            <East className='next-arrow' fontSize="small"/>
                        </div>
                    }>
                    <East className="go-back-market sedeo-button-rounded" 
                        onClick={() => router.push(currentEvent.defaultMarketRedirectionUrl)}
                        fontSize="small"
                        color="inherit"
                        />
                </Tooltip>
                </>
            }

        </Card>

        <EventDataFormDialog
            onCancel={() => {
                setOpenEventDataFormDialog(false)
            }}
            onSuccess={(spatialToken: string, defaultMarketRedirectionUrl: string) => {
                setOpenEventDataFormDialog(false)
            }} // User stays on the same page
            open={openEventDataFormDialog}
        />
    </>
}