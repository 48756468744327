
export enum PAGE_STATUS {
    LOADING = 'loading',
    ERROR = 'error',
    DATA = 'data',
    EMPTY_STATE = 'empty_state',
    SSG = 'ssg'
}

export interface LoadingState {
    status: PAGE_STATUS.LOADING
}

export interface ErrorState {
    status: PAGE_STATUS.ERROR
    error: string
}

export interface DataState {
    status: PAGE_STATUS.DATA
}

export interface EmptyState {
    status: PAGE_STATUS.EMPTY_STATE
}

export interface SsgState {
    status: PAGE_STATUS.SSG
}


